import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AppBaseDataService } from '../_services/app-base-data.service';
import { SignupService } from '../_services/signup.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    _globals = GlobalService

    constructor(private _userService: UserService,
        private _router: Router,
        private _signupService: SignupService,
        public _abd: AppBaseDataService,
        private permissionsService: NgxPermissionsService,
        ) { }

    canActivate(): Observable<boolean> {
        // const x = window.location.host
        let isBusinessSetup = false
        return this._userService.currentUser$.pipe(
        map(user => {
            if (!user) {
                if (window.location.href.includes('/handheld'))
                    this._router.navigate(['handheld/signin2'])
                else
                    this._router.navigate(['pages/signin'])
                return
            }

            try {

                if (this._globals.userInfo) {
                    // this.loadPermissions()
                    this.redirectToSignup();
                    // ----
                    this._globals.userInfo.isBusinessSetup = true
                    // --------
                    if (this._globals.userInfo.isBusinessSetup) isBusinessSetup = true
                    else {

                        if (
                            this._globals.userInfo.businessSetup[0].isFreeTrial ||
                            this._globals.userInfo.businessSetup[0].isSubscriptionCompleted)
                        {
                            this._router.navigate(['setup/business-setup'])
                        }

                    }
                }
                else {

                    this._userService.getUserByUserName(this._userService.currentUserObj.userName).subscribe(res => {
                    if (res.success) {
                        try {
                        const xuser = res.data
                        this._globals.userInfo = xuser
                        // this.loadPermissions()    
                        this.redirectToSignup();

                            xuser.isBusinessSetup = true;
                        if (xuser.isBusinessSetup) isBusinessSetup = true
                        else this._router.navigate(['setup/business-setup'])
                        } catch { }
                    }
                    })
                }

                if (user && isBusinessSetup) return true;
            } catch { }
            this._abd.initMenuList(false)
        })

        )
    }
   
    loadPermissions(){
            // console.lo g('Load Permissions', this._globals.userInfo.userRights)
            return this.permissionsService?.loadPermissions(this._globals.userInfo.userRights);
      }

    redirectToSignup(){
        sessionStorage.setItem('un-verified-account', "0")
         // //// Signup-Redirect
        if (!this._globals.userInfo.businessSetup[0].isFreeTrial &&
            !this._globals.userInfo.businessSetup[0].isSubscriptionCompleted
            ) {
                sessionStorage.setItem('un-verified-account',"1")
                this._signupService.setInitialSubscriptionDetail(this._globals.userInfo);
                this._router.navigate(['setup/subscription-plans'])
                return false
            }

        // //// Signup-Redirect
        if (
            this._globals.userInfo.businessSetup[0].isSubscriptionCompleted
            && window.location.href.includes('/setup/subscription-plans')
        ) {


            this._router.navigate(['/'])
            return false
        }
        // //// Signup-Redirect 
    }

}
