import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import { Subscription, Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { SigninModel } from 'src/app/_models/user/signin';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styles: [`
    :host ::ng-deep .p-password input {
    width: 100%;
    padding:1rem;
    }

    :host ::ng-deep .pi-eye{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }

    :host ::ng-deep .pi-eye-slash{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }
  `]
})
export class SigninComponent implements OnInit, OnDestroy {

  rememberMe: string[] = ['remember'];
  password: string;
  email: string;
  signinForm: FormGroup
  forgotpasswordForm: FormGroup

  mode: string = 'l' // l=login, f=forgot password
  isAdmin:boolean = false;
  config: AppConfig;
  subscription: Subscription;
  _globals = GlobalService

  constructor(
    public _configService: ConfigService,
    private _router: Router,
    private _fb: FormBuilder,
    public _userService: UserService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    if (window.location.host.includes('admin.connect') || window.location.host.includes('admin.') || window.location.host.includes('lsocalhost')) {
      this.isAdmin = true;
    }else{
      this.isAdmin = false
    }
    try {
        document.documentElement.style.setProperty('--brandColor', this._globals.whiteLabel.brandColor);
        document.documentElement.style.setProperty('--bs-primary', this._globals.whiteLabel.brandColor);
        this.config = this._configService.config;
        this.subscription = this._configService.configUpdate$.subscribe(config => {
            this.config = config;
        });
        this.intitializeForm()
        this.initForgotpasswordForm()

        let paymentSuccessfully: any = sessionStorage.getItem('payment-successfully');
        if (paymentSuccessfully != undefined) {
            sessionStorage.clear();
        }
    } catch (error) {

    }
  }
  open(){
    // window.open('https://lincsell.com/pricing/')
   window.location.href = 'https://lincsell.com/pricing/';
  }
  intitializeForm() {
    this.signinForm = this._fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
      rememberMe: ['']
    })
  }

  initForgotpasswordForm() {
    this.forgotpasswordForm = this._fb.group({
      email: ['', [Validators.required]],
    })
  }

  isHandheld() {
    if (window.location.href.includes('/handheld'))
      return true
    else
      return false
  }



  emailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this._globals.emailValidatorString)
      return pvalid ? null : { emailStrength: true };
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  signin() {
    this._globals.loginErrorMessage = '';
    localStorage.removeItem('user')
    this._abd.showSpinner();
    const x = this.signinForm.value
    const signin: SigninModel = {
      Username: x.email,
      Password: x.password,
      rememberMe: x.rememberMe ? true : false
    }
    this._userService.login(signin)
  }

  setMode(val) {
    this.mode = val
  }

  forgotpasswordSubmit() {
    const email = this.forgotpasswordForm.controls.email.value
    this._abd.showSpinner()
    this._userService.sendForgotpasswordEmail(email).subscribe((res: any) => {
      if (res.success) {
        this._abd.hideSpinner()
        if (res.data) {
          this._connMessageService.showToastMessage('Email has been sent on provided email. Please check your email!', 'success')
          this._router.navigate(['pages/signin'])
        } else {
          this._connMessageService.showToastMessage(res.message, 'error')
          this._router.navigate(['pages/signin'])
        }
      } else {
        this._abd.hideSpinner()
      }
      this._router.navigate(['pages/signin'])
    })
  }

  signupClicked() {
    let url = this._globals.whiteLabel.signupURL
     // this._router.navigate(['setup/subscription-plans'])
  }



  modeChanged() {
    this.mode = this.mode === 'l' ? 'r' : 'l'
  }


}
