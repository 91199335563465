   <div class="flex justify-content-center align-items-center flex-column h-screen" >
        <div class="flex justify-content-center  flex-column align-items-center ">
            <div class="flex justify-content-center my-1">
                <img src="{{_globals.whiteLabel.logo}}" alt="{{_globals.whiteLabel.wlName}} logo" style="width:180px;">
            </div>
            <div class="flex justify-content-center p-4 flex-column align-items-center ">
                <div class="flex justify-content-center my-1">
                    <img src="assets/images/Verification.png" />
                </div>
                <div class="flex justify-content-center my-2 ">
                    <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                     Your subscription has been started!  </div>
                     
                </div>
            </div>
            <div class="flex justify-content-center my-2 ">
                <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                    Merchant Application Form for Credit Card Processing</div>
            </div>
        </div>
         <span class="xxl:col-8 xl:col-8 lg:col-8 md:col-10 sm:col-10 flex ">
            <!-- In order to gain the right to process the payments inside the {{_globals.whiteLabel.wlName}}. We need you to fill the personal details in the merchant application form (MAF). The application involves the information about the organization, the executives along with your partners, and personal data about your business type along with the information about the account in the financial institution -->
            To gain authorization for processing payments within {{_globals.whiteLabel.wlName}}, please complete the Merchant Application Form (MAF). This application requires detailed information about your organization, its executives and partners, as well as personal and business data, including financial institution account information.
         </span>
        <div class="flex align-items-end justify-content-center">

            <a class="btn p-button-link cursor-pointer text-lg"
                style="color: var(--brandColor); cursor:pointer;"
               
                (click)="merchanApplication()"
                >
                <div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">
                    Complete Merchant Application Form
                </div>
            </a>

            <a class="btn p-button-link cursor-pointer text-lg"
                style="color: var(--brandColor); cursor:pointer;"
               
                (click)="login()"
                >
                <div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">
                    Click here to login    <i class="pi pi-arrow-right mx-2"></i>
                </div>
            </a>
        </div>
    </div>

 <div class="flex justify-content-center align-items-center flex-column h-screen" *ngIf="!isRealAccountAccess">
     <div class="flex justify-content-center p-4 flex-column align-items-center ">

         <div class="flex justify-content-center my-2 ">
             <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                You don't have not access to this page we are redirecting you to login page in ({{timeLeft}}
                seconds)...</div>
         </div>
     </div>
 </div>

