import { ProductsService } from 'src/app/_services/products.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from 'src/app/_services/global.service';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  _global = GlobalService
  editform: FormGroup
  url:any =null;
  // url:any ="https://lincsell.com/";
  isUrlLoad:boolean = false;
  constructor(
    public _productServices: ProductsService,
    private _fb: FormBuilder,
    public _platform: Platform,
    public _abd:AppBaseDataService,
    private  _http:HttpClient,
    private _connMessageService: ConnMessageService,
    private clipboard: Clipboard,
  ) { this.initForm()}

  ngOnInit(): void {
     this.initForm()
     if(!this._productServices.isNew && this._abd.selectedProductSellType.id === 1){
       this.getUrl()
     }
  }
  copy(text:any){
    this.clipboard.copy(text);
     this._connMessageService.showToastMessage('copied successfully', 'success');
   }
getUrl(){
  this.isUrlLoad =true
  try {
    //  let url =  environment.BusinessBaseURL +'product-url?url='+ this._global.webStoreConfiguration?.webStoreHost+'/wp-json/back-office-apis/v1/product-url/?sku=1111111111'
     let url =  environment.BusinessBaseURL +'product-url?url='+ this._global.webStoreConfiguration?.webStoreHost+'/wp-json/back-office-apis/v1/product-url/?sku='+this._productServices.pts.sku
  this._http.get<any>(url).subscribe({
    
    next: (res) =>{
      this.isUrlLoad =false
      if(res.success){
                this.url = res.data?.url;
              }else{
               this.url = null 
               this._connMessageService.showToastMessage('No Product url found!', 'error');
              }
    },
    error: (error) => {
      this.isUrlLoad =false
      // console.error('Error fetching data:', error)
    }
  }
)
  } catch (error) {
      // console.log(error) 
  }
}

  initForm() {
    // alert(7667)
    this.editform = this._fb.group({
      productName: [{value: this._productServices.pts.t_productName, disabled: this._productServices.isViewOnly}, Validators.required],
      descriptionUrl:[{value: this._productServices.pts.descriptionUrl, disabled: this._productServices.isViewOnly}],
      //productDescription:['']
    })
  }

  isFormValid() {
    return this.editform.valid
  }




  
}
