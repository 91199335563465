<div class="flex">
    <form
        [formGroup]="purchaseOrderConfigurationForm"
        (keydown.enter)="$event.preventDefault()"
        class="w-full"
        (ngSubmit)="saveConfigurationInfo()" autocomplete="off">
        <!-- <div class="flex align-items-center">
            <div class="col-12 md:col-1">
                <p-checkbox
                    [(ngModel)]="inputSendEmail"
                    [binary]="true"
                    [ngModelOptions]="{standalone: true}"
                    inputId="SendEmail"></p-checkbox>
            </div>
            <label class="mb-2  md:mb-0"  for="SendEmail">Send Purchase Order Via Email</label>
        </div> -->
        <div class="flex align-items-center">
            <div class="col-12 md:col-1">
                <p-checkbox
                    [(ngModel)]="inputEnableBody"
                    [ngModelOptions]="{standalone: true}"
                    [binary]="true" inputId="isStandardEmail"></p-checkbox>
            </div>
            <label class="mb-2 md:mb-0"  for="isStandardEmail">Enable standard email body template</label>

        </div>

        <div  class="flex card dotted-border-bottom box-shadow-none"
            [ngClass]="{'disabled-input': !inputEnableBody }">
            <div class="field">
                <label htmlfor="InputMaxPointsAllowed" class="flex justify-content-between align-items-center"> <span>Note</span> <p>Restore default email template <i class="pi pi-refresh p-2 cursor-pointer" style="color:var(--brandColor)" (click)="resetDefaultEmail()"></i></p> </label>
                <p-editor formControlName="InputBodyText" [style]="{'height':'320px','width':'100%'}">
                </p-editor>
            </div>
        </div>
        <div class="flex align-items-center">
            <div class="col-12 md:col-1">
                <p-checkbox
                    [(ngModel)]="inputEnableFooter"
                    [ngModelOptions]="{standalone: true}"
                    [binary]="true" inputId="isFooterNote"></p-checkbox>
            </div>
            <label class=" mb-2 md:mb-0"  for="isFooterNote">Enable Footer Notes Configuration</label>

        </div>

        <div  class="flex card dotted-border-bottom box-shadow-none mb-5"
            [ngClass]="{'disabled-input': !inputEnableFooter }">
            <div class="field">
                <label htmlfor="InputMaxPointsAllowed">Note</label>
                <textarea [rows]="5" [cols]="60" [autoResize]="true" class="mt-2"  [formControl]="$any(purchaseOrderConfigurationForm.controls)['InputFooterText']" pInputTextarea
                style="width: 100%;">​</textarea>
                <span class="control-description text-muted"> You can only add 80 characters in Footer Note</span>
            </div>
        </div>
          <div class="grid" style="margin-top: 10px">
              <div class="col" style="margin-top: 7px">
                  <div class="flex justify-content-start flex-wrap card-container green-container mt-3">
                      <div class="flex align-items-center justify-content-center">
                          <button  pButton pRipple type="submit" [disabled]="purchaseOrderConfigurationForm.invalid"
                              label="Save"></button>
                      </div>
                  </div>
              </div>
          </div>
    </form>
</div>
