import { IndexedDBService } from './indexed-db.service';
import { AppBaseDataService } from './app-base-data.service';
import { GlobalService } from './global.service';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';
import { User } from '../_models/user/user';
import { BaseService } from './base.service';
import { SigninModel } from '../_models/user/signin';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment'
import { WhiteLabelList } from '../_data/white-label-list';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SignupService } from './signup.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();
  currentUserObj: User = null

  selectedTimeZone: any =  { label: 'United States (New York)', value: 'America/New_York' }
  _globals = GlobalService
  showNewEdit = false
  isViewOnly = false
  activeItem: any = ''
  isNew = false
  isCheckIn = false
  bypassUrls = ['/pages/aboutus', '/pages/emailverified']
  isCheckInEnable: boolean = false;

  constructor(
    private _bs: BaseService,
    private _router: Router,
    private _abd: AppBaseDataService,
    private title: Title,
    private _signupService: SignupService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private permissionsService: NgxPermissionsService,
  ) { }

  login(signin: SigninModel) {
    localStorage.clear()

    this._bs.httpPost(environment.AzureAPIURLForLogin, signin).subscribe((res: any) => {

      if (res.success) {

        sessionStorage.clear();
        const model: User = {
          userID: '',
          userName: signin.Username,
          displayName: '',
          token: res.data.access_token,
          rememberMe: true, //signin.rememberMe,
          expires_in: res.data.expires_in,
          refreshToke: res.data.refresh_token,
          photo: '',
          type: ''
        }
      //  this.timeTracking(model,res.data).then(
      //   res=>{
      //     this.setCurrentUser(model)
      //     this.autologin(res.data)
      //  },error =>{
      //   this.setCurrentUser(model)
      //   this.autologin(res.data)
      //  })
        this.setCurrentUser(model)
        this.autologin(res.data)
        // this.setUserTokenExpiryTime()
        // this.getUserByUserName(signin.Username)
        // this.getUserRoles()
        // this._abd.loadInit()
        // this._router.navigate(['/']);


      }
    })
  }
  // timeTracking(model?:any,user?:any): Promise<any> {
  //  let timeConfigration;
  //   return new Promise<any>((resolve, reject) => {
  //     this._abd.showSpinner()
  //     this.setCurrentUser(model)
  //           let wll: any = null
  //       if (!wll) wll = WhiteLabelList[0] // to be deleted later
  //       this._globals.whiteLabel = wll,
  //       this._abd.getTimeTrackingConfiguration().subscribe((timeRes)=>{
  //           timeConfigration = timeRes.data
  //         if(timeConfigration.active === true){
  //           localStorage.setItem('enableTimeAttendance','true')  
  //         //      if(timeConfigration.forceCheckIn === true || timeConfigration.autoCheckInOnLogin === true){
  //         //    localStorage.setItem('userCheckInType','force-checkIn')
  //         // }
  //         }
  //         })
  //         setTimeout(() => {
  //           this._abd.getTimeTrackingConfiguration().subscribe((timeRes)=>{
  //             timeConfigration = timeRes.data
  //           if(timeConfigration.active === true){
  //             localStorage.setItem('enableTimeAttendance','true')  
  //           //      if(timeConfigration.forceCheckIn === true || timeConfigration.autoCheckInOnLogin === true){
  //           //   localStorage.setItem('userCheckInType','force-checkIn')
  //           // }
  //           }
  //           })
  //         }, timeConfigration.totalWorkingHours * 3600 * 1000);
  //       resolve ('OK');
  //   });
  // }

  sendForgotpasswordEmail(email: string) {
    let url = environment.BaseURL + environment.ForgotPassword
    return this._bs.httpPost(url, JSON.stringify({
      Username: email
    })).pipe(((response: any) => {
      return response;
    }))
  }

  resetPassword(password: string, userID: string) {
    let url = environment.AzureUserInfo;
    return this._bs.httpPost(url, JSON.stringify({
      "Name": "CHANGEPASSWORD",
      "User": {
        "UserID": userID,
        "Password": password
      }
    })).pipe(((response: any) => {
      return response;
    }))
  }

  changePassword(user: any) {
    let url = environment.AzureUserInfo

    return this._bs.httpPost(url, JSON.stringify({
      "Name": "CHANGEPASSWORD",
      "User": user
    })).pipe(((response: any) => {
      return response;
    }))
  }

  isBypassUrl(url: string) {
    url = url.toLowerCase()
    for (let i = 0; i < this.bypassUrls.length; i++) {
      if (url.includes(this.bypassUrls[i].toLowerCase())) {
        return true
      }
    }
    return false
  }

  loadAppDataFromIndexedDb() { }

  autologin(loginResponseData?: any) {

    window.addEventListener("load", () => {
      try {

        eval('invokeCSharpAction("pageloaded")')
      }
      catch
      {

      }
    });

    const sourceUrl = (window.location.href).toLowerCase()
    let wll: any = null

    if (this.isBypassUrl(sourceUrl)) {
      window.location.href = sourceUrl
      return
    }
    // if (window.location.host.includes('admin.connect-360') || window.location.host.includes('admin.') || window.location.host.includes('admin.connect-cbd') || window.location.host.includes('admin.com')) {
      wll = WhiteLabelList[0]
    // }

    // else {
    //   if (window.location.host.includes('localhost:')) {
    //     wll = WhiteLabelList[0]

    //   }
    //   else if (window.location.host.includes('connect-360')) {
    //     wll = WhiteLabelList[0]

    //   }
    //   else if (window.location.host.includes('connect-cbd')) {
    //     wll = WhiteLabelList[0]
    //   }
    // }

    // if (!wll) wll = WhiteLabelList[0] // to be deleted later

    if (wll) {
      try {
        this._abd.showSpinner()
        this._globals.whiteLabel = wll

        this.setCurrentUserFromLocalStorage()  
         this.getUserByUserName(this.currentUserObj.userName).subscribe(res => {
          if (res.success) {
            const user = res.data
            this.loadPermissions(user?.userRights);
            this._globals.userInfo = user
            this.getWebStoreConfiguration().subscribe((res)=>{
              if(res.success){
                this._globals.webStoreConfiguration = res.data;
              }
            })
            sessionStorage.setItem('un-verified-account', "0")
            // //// Signup-Redirect
            if (!res.data.businessSetup[0].isFreeTrial && !res.data.businessSetup[0].isSubscriptionCompleted
            ) {
              sessionStorage.setItem('un-verified-account', "1")
              this._signupService.setInitialSubscriptionDetail(this._globals.userInfo);


              this._router.navigate(['setup/subscription-plans'])
            }
            // //// Signup-Redirect
            if (this._globals.userInfo.changePassword) {
              this._abd.hideSpinner()
              this._router.navigate(['pages/change-password'])
            } else {

              if (res.data.businessSetup[0].isSubscriptionCompleted || res.data.businessSetup[0].isFreeTrial) {
                this.getUserRoles()
              }

              this._document.getElementById('appFavicon')?.setAttribute('href', 'assets/images/' + wll.favIcon + '.ico')
              this.title.setTitle(wll.wlName)
              this._abd.loadInit().subscribe(res1 => {
                this._abd.hideSpinner()
                if (res1.success) {
                  // ------------
                  user.isBusinessSetup = true
                  // -------------
                  if (user.isBusinessSetup) {
                    if (window.location.href.includes('/handheld')) {
                      this._router.navigate(['handheld/inventory-report'])
                    }
                    else if (window.location.host.includes('admin.'))
                    {
                      this._router.navigate(['bizz/customers-list'])
                     
                    }
                    else { 
                      // this._abd.showSpinner()
                      let iswebStoreSelectedDefault:boolean = false
                      if(this._globals.webStoreConfiguration &&this._globals.webStoreConfiguration.length > 0){
                      
                        iswebStoreSelectedDefault = this._globals.webStoreConfiguration.filter(v => v.isDefault === true)?.length > 0 ? true : false 
                        

                        if(this._globals.webStoreConfiguration.length > 0 && !iswebStoreSelectedDefault){
                          this._router.navigate(['theme-select'])
                        }else{
                          let href = localStorage.getItem('goto')
                          if (href?.includes('/pages/signin')) this._router.navigate([""]);
                          else if (href) window.location.href = localStorage.getItem('goto')+'/';
                          else this._router.navigate([""]);
                        }
                      }else{
                         this.getWebStoreConfiguration().subscribe((res)=>{
                        if(res.success){
                            this._globals.webStoreConfiguration = res.data
                            if(this._globals.webStoreConfiguration?.length > 1 ){
                              this._router.navigate(['theme-select'])
                            }else{
                              let href = localStorage.getItem('goto')
                              if (href?.includes('/pages/signin')) this._router.navigate([""]);
                              else if (href) window.location.href = localStorage.getItem('goto')+'/';
                              else this._router.navigate([""]);
                            }
                        }
                      },(error:any)=>{
                        let href = localStorage.getItem('goto')
                        if (href?.includes('/pages/signin')) this._router.navigate([""]);
                        else if (href) window.location.href = localStorage.getItem('goto')+'/';
                        else this._router.navigate([""]);
                      })
                      }
                 

    
                    //  // //this._router.navigate([""]);
                
                    //   let href = localStorage.getItem('goto')
                    //   if (href?.includes('/pages/signin')) this._router.navigate([""]);
                    //   else if (href) window.location.href = localStorage.getItem('goto')+'/';
                    //   else this._router.navigate([""]);
                    }
                  }
                  else
                    this._router.navigate(['setup/business-setup'])
                }

                return res

                // let enable = localStorage.getItem('enableTimeAttendance')
                // if(enable === "true"){
                  // this.isCheckInEnable = true
                  // this._spinner.show()
                // this.getTimeAttendance(user.userID).subscribe((res)=>{
                  // if(res.data.type === 0){
                    // localStorage.setItem('userAttendance','check-in')
                    // let obj ={
                    //   UserId:        res.data?.userId,
                    //   EmployeeID:    res.data?.userId,
                    //   EmployeeRole:  res.data?.employeeRole,
                    //   EmployeeEmail: res.data?.employeeEmail, 
                    //   EmployeeName:  res.data?.employeeName,
                    //   LocationName:  res.data?.locationName,
                    //   RegisterName:  res.data?.registerName,
                    //   locationid:    res.data?.locationId,
                    //   Type:          res.data?.type,
                    //   Method:        res.data?.method,
                    //   reason:        res.data?.reason,
                    //  }
                    // localStorage.setItem('userCheckIn',JSON.stringify(obj))
                  // }else if(res.data.type === 1){
                    // localStorage.setItem('userAttendance','not-check-in')
                  // }
                  // let userAttendance = localStorage.getItem('userAttendance');
                  // let userForceCheckIn = localStorage.getItem('userCheckInType')
                  // if(userAttendance === "not-check-in"){
                    // this.isCurrentCheckIn = false
                    // if(userForceCheckIn === 'force-checkIn'){
                    //   this.isCheckIn = true
                  //   //  }
                  // }else if(userAttendance === 'check-in'){
                  //   this.isCurrentCheckIn = true
                  // }
                  // this._spinner.hide()
                  // return res
                // },(error)=>{
                  // let userAttendance = localStorage.getItem('userAttendance');
                  // let userForceCheckIn = localStorage.getItem('userCheckInType')
                  // if(userAttendance === "not-check-in"){
                  //  if(userForceCheckIn === 'force-checkIn'){
                  //   this.isCheckIn = true
                  //  }
                  //   this.isCurrentCheckIn = false
                  // }else if(userAttendance === 'check-in'){
                  //   this.isCurrentCheckIn = true
                  // }
                 
                  // this._spinner.hide()
                  // return res
                // })
                // }else{
                //   this.isCheckInEnable = false;
                // }
                

                //   this.isCheckInEnable = true
                //   this._spinner.show()
                // this.getTimeAttendance(user.userID).subscribe((res)=>{
                //   if(res.data.type === 0){
                //     localStorage.setItem('userAttendance','check-in')
                //     let obj ={
                //       UserId:        res.data?.userId,
                //       EmployeeID:    res.data?.userId,
                //       EmployeeRole:  res.data?.employeeRole,
                //       EmployeeEmail: res.data?.employeeEmail, 
                //       EmployeeName:  res.data?.employeeName,
                //       LocationName:  res.data?.locationName,
                //       RegisterName:  res.data?.registerName,
                //       locationid:    res.data?.locationId,
                //       Type:          res.data?.type,
                //       Method:        res.data?.method,
                //       reason:        res.data?.reason,
                //      }
                //     localStorage.setItem('userCheckIn',JSON.stringify(obj))
                //   }else if(res.data.type === 1){
                //     localStorage.setItem('userAttendance','not-check-in')
                //   }
                //   let userAttendance = localStorage.getItem('userAttendance');
                //   // let userForceCheckIn = localStorage.getItem('userCheckInType')
                //   if(userAttendance === "not-check-in"){
                //     this.isCurrentCheckIn = false
                //     // if(userForceCheckIn === 'force-checkIn'){
                //     //   this.isCheckIn = true
                //     //  }
                //   }else if(userAttendance === 'check-in'){
                //     this.isCurrentCheckIn = true
                //   }
                //   this._spinner.hide()
                //   return res
                // },(error)=>{
                //   let userAttendance = localStorage.getItem('userAttendance');
                //   // let userForceCheckIn = localStorage.getItem('userCheckInType')
                //   if(userAttendance === "not-check-in"){
                //   //  if(userForceCheckIn === 'force-checkIn'){
                //   //   this.isCheckIn = true
                //   //  }
                //     this.isCurrentCheckIn = false
                //   }else if(userAttendance === 'check-in'){
                //     this.isCurrentCheckIn = true
                //   }
                 
                //   this._spinner.hide()
                //   return res
                // })
                // }else{
                  // this.isCheckInEnable = false;
                // }
        
              })
            }

          }
        },(error)=>{
          localStorage.clear()
          sessionStorage.clear()
          this._router.navigate(['pages/signin'])
        })
      } catch {
        if (window.location.href.includes('/handheld'))
          this._router.navigate(['handheld/signin'])
        else {

          const url = window.location.href;
          let planId;

          if (url.includes('setup/signup?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            planId = httpParams.get("selected_plan");
          }
         if(url.includes('/setup/subscription-payment-success-merchant')){
          this._abd.hideSpinner()
          return  this._router.navigate(['/setup/subscription-payment-success-merchant'])
         }else if(url.includes('/setup/signup')){
         if(sessionStorage.getItem('selectedPlan') !== null){
          this._signupService.selectedPlan = JSON.parse(sessionStorage.getItem('selectedPlan'))
         }
         
          this._abd.hideSpinner()
          return  this._router.navigate(['/setup/signup'])
         }

          if (planId != "" && planId != null) {
            sessionStorage.setItem('selected-from-business-website', "1")
          }
          else {
            this._router.navigate(['pages/signin'])
          }
        }
        this._abd.hideSpinner()
      }
    } else {
      this._router.navigate(['/error']);
    }
  }
  loadPermissions(userRights:any[]){
    // console.lo g('Load Permissions', this._globals.userInfo.userRights)
    return this.permissionsService?.loadPermissions(userRights);
}
   getTimeAttendance(userID:any){
    let url = environment.BusinessBaseURL +'GetTimeAttendanceReport'
    let obj = {
      UserId:userID,      
      Report:0,
      PageNo : 0,
      PageSize : 1,
}
    return this._bs.httpPost(url,obj).pipe(map(res => {
     return res;
   }))
   }
  checkInUser(queryParem:any,userData:any){
   let url = environment.BusinessBaseURL +`SetTimeAttendance?DateTimeEnable=true&CheckInTime=${queryParem}`
   return this._bs.httpPost(url, userData).pipe(map(res => {
    return res;
  }))
  }
  checkOutUser(queryParem:any,userData:any){
    let url = environment.BusinessBaseURL +`SetTimeAttendance?DateTimeEnable=true&CheckInTime=${queryParem}`
     return this._bs.httpPost(url, userData).pipe(map(res => {
      return res;
    }))
  }
  getWebStoreConfiguration(){
    const url = environment.BusinessBaseURL + "webstore/configuration"
    return this._bs.httpGet(url).pipe(map((res: any) => {
      return res
    }))
  }
  getUserRoles() {
    const url = environment.BusinessBaseURL + environment.GetRightsGroup
    this._bs.httpGet(url).subscribe((res: any) => {
      if (res.success) {
        this._globals.userRoles = res.data
      }
    })
  }

  getRoles() {
    const url = environment.BusinessBaseURL + environment.GetRoles
    return this._bs.httpGet(url).pipe(map((res: any) => {
      return res
    }))
  }
  getTimeAttendaceList(obj) {
    let url = environment.InventoryBaseURL + `Dashboard/GetEmployeeTimeStatus?TimeZone=${obj.value}`
    return this._bs.httpGet(url).pipe(map((res: any) => {
      return res
    }))
  }

  getPosRights() {
    const url = environment.BusinessBaseURL + 'GetBusinessRightsStructured'
    const obj = {
      //"plateform": "BusinessDashboard"
      "plateform": "POSAPP"
      // "plateform": "All"
    }
    return this._bs.httpPost(url, obj).pipe(map(res => {
      return res;
    }))
  }

  getBackOfficeRights() {
    const url = environment.BusinessBaseURL + 'GetBusinessRightsStructured'
    const obj = {
      "plateform": "BusinessDashboard"
      // "plateform": "POSAPP"
      // "plateform": "All"
    }
    return this._bs.httpPost(url, obj).pipe(map(res => {
      return res;
    }))
  }

  getRights(platform: string = 'All') {
    const url = environment.BusinessBaseURL + environment.GetRights
    return this._bs.httpPost(url, JSON.stringify({ "plateform": platform }))
      .pipe(map(res => {
        return res;
      }))
  }

  getRightGroups() {
    let url = environment.BusinessBaseURL + environment.GetRightsGroup;

    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  getUserByUserName(name: string) {
    const model = {
      Name: "GETUSERBYUSERNAME",
      User: {
        Username: name
      }
    }
    return this._bs.httpPost(environment.AzureUserInfo, model, true, false).pipe(map(res => {
      if (res.success) {
        this._globals.userInfo = res.data
        // console.log('this._globals.userInfo', this._globals.userInfo)
        return res
      }
    }))
  }

  setCurrentUser(user: User) {
    //const dt = Date.now() + (1 * 60 * 60 * 1000)
    const dt = Date.now() + ((user.expires_in - 3600) * 1000)
    localStorage.setItem('lastLoginDT', dt + '');

    const userString = JSON.stringify(user)
    localStorage.setItem('user', userString);
    this.currentUserObj = JSON.parse(userString)
    this.currentUserSource.next(user);
  }

  setCurrentUserFromLocalStorage() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.currentUserObj = user
      this.currentUserSource.next(user);
    }
  }

  getUserToken(): any {
    if (!this.currentUser$) return null
    if (this.currentUserObj.token.length < 20) return null
    return this.currentUserObj.token
  }

  setUserTokenExpiryTime() {
    const today = new Date()
    const timeObject = new Date(today.getTime() + (this.currentUserObj.expires_in * 1000))
    this.currentUserObj.tokenExpiryTime = timeObject
    this.setCurrentUser(this.currentUserObj)
  }

  isUserAutoLogin() {
    const model = JSON.parse(localStorage.getItem('user'));
    if (!model) return false
    if (!this.isUserTokenExpired() && model.rememberMe) return true
    return false
  }

  isUserTokenExpired() {
    const model = JSON.parse(localStorage.getItem('user'));
    if (!model) return false
    const tdate = new Date(model.tokenExpiryTime)
    const today = new Date()
    return today > tdate ? true : false
  }

  getRefreshToken(model) {
    const url = `${environment.AuthenticationURL}RefreshToken`

    return this._bs.httpPost(url, JSON.stringify(model)).pipe(map(res => {
      return res;
    }))
  }

  logout() {

    try {

      if (window.location.href.includes('/handheld')) {
        // console.log("pagechanged");
        eval('invokeCSharpAction("pagereloaded")')
      }


    }
    catch
    {

    }
    localStorage.removeItem('user');
    localStorage.removeItem('lastLoginDT');
    window.location.href=window.location.origin
    this.currentUserSource.next(null);
    this.currentUserObj = null
  }

  getAllUsers(model) {
    const url = environment.AzureUserInfo;

    return this._bs.httpPost(url, JSON.stringify(model)).pipe(map(res => {
      return res;
    }))
  }

  //----------------------------
  createStaffUser(user: any) {
    let url = environment.AzureUserInfo
    return this._bs.httpPost(url, user).pipe(map(res => {
      if (res.success)
        this.getUserByUserName(this._globals.userInfo.username).subscribe(() => { })
      return res;
    }))
  }

  createStaffUser_ForSetupOnly(user: any) {
    let url = environment.BusinessBaseURL + environment.SetupBusiness
    return this._bs.httpPost(url, user).pipe(map(res => {
      if (res.success)
        this.getUserByUserName(this._globals.userInfo.username).subscribe(() => { })
      return res;
    }))
  }

  businessWizardComplete() {
    let url = environment.BusinessBaseURL + environment.SetBusinessWizardFinished;
    return this._bs.httpPost(url, JSON.stringify({ IsBusinessSetup: true })).pipe(map(response => {
      return response;
    }))
  }

  deactivateUser(userId: string, status: boolean, pageNumber: number, pageSize: number) {
    let url = environment.AzureUserInfo;

    return this._bs.httpPost(url, JSON.stringify({
      "Name": status ? "ACTIVATEUSER" : 'DEACTIVATEUSER',
      "User": {
        "UserID": userId,
      },
      "PageNo": pageNumber,
      "PageSize": pageSize
    })).pipe(map(response => {
      return response;
    }))
  }
}
