import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TenderScreenConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-tender-configuration',
  templateUrl: './tender-configuration.component.html',
  styleUrls: ['./tender-configuration.component.scss']
})
export class TenderConfigurationComponent implements OnInit {

  tenderForm: FormGroup;
  currentSelectedLocation:any = null;
  tenderSettingList:any =null
  
    @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  isDisabled = true
    constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _confirmationService: ConfirmationService,
              private _messageService: MessageService,
              public _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.currentSelectedLocation = this._abd.locations[0]
// setTimeout(() => {
  this.getTenderConfigration(this.currentSelectedLocation?.locationId)
// }, 1000);
 
  }  

  // locationChanged(locationId:any){
  //  this.tenderSettingList = null
  //  this.getTenderConfigration(this.currentSelectedLocation?.locationId)
  // }
  createForm(): void {
    this.tenderForm = this._fb.group({
      locationId: this.tenderSettingList.locationId,
      enableTip: this.tenderSettingList.enableTip || false,
      promptCustomerTip: this.tenderSettingList.promptCustomerTip,
      enableCashDisc: this.tenderSettingList.enableCashDisc,
      tenderTypes: this._fb.array([])
    });

    this.tenderSettingList.tenderTypes.forEach(tenderType => {
       this.tenderTypes.push(this.addTenderType(tenderType)) 
    });

    this.tenderForm.valueChanges.subscribe(data => {
      this.tenderSettingList = data;
    });
  }
  get f(){
    return this.tenderForm?.controls
  }
  get tenderTypes() {
    return this.tenderForm.get('tenderTypes') as FormArray;
  }
  addTenderType(tenderType: any): FormGroup { 
    return this._fb.group({
      locationId: [tenderType.locationId],
      tenderId:[tenderType.tenderId],
      tenderType: [tenderType.tenderType],
      displayName: [tenderType.displayName],
      cashDiscountPer: [tenderType.cashDiscountPer],
      enableTip: [tenderType.enableTip],
      promptCustomerTip: [tenderType.promptCustomerTip],
      enableCashDisc: [tenderType.enableCashDisc],
      status: [tenderType.status],
      isCash: [tenderType.isCash],
      showRemarks: [tenderType.showRemarks],
    });

  }

  getTenderConfigration(value?:any){
  // let model = `locationid=${value !== undefined ? value : 0}&BackOffice=true`
  let model = `locationid=0&BackOffice=true`
  try{
      this._abd.showSpinner()
      this._abd.getTenderConfiguration(model).subscribe(res=>{
        if(res.success){
          this.tenderSettingList = res.data
          this.createForm()
          this._abd.hideSpinner()
        }else{
          this.tenderSettingList = {}
        }
      })
    }
    catch{
      this._abd.hideSpinner()
    }
  }


  saveFn(){
  //   if(this._abd.locations.length < 1){
  //     return  this._messageService.add({ severity: 'error', summary: 'Location required. Please add a location to proceed.' })
  // }
  // let queryString = `AllLocation=true&BackOffice=true&LocationIds=${this.currentSelectedLocation.locationId}`
  let queryString = `AllLocation=true&BackOffice=true&LocationIds=0`
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveTenderConfiguration(queryString,this.tenderForm.value).subscribe(res=>{
        if(res.success){
           this._connMessageService.showToastMessage(`Tender settings saved successfully!`, 'success')
           this._abd.systemConfigs.tenderScreenConfig.isSaved = true
           this._businessSetupService.showTenderConfiguration = false
           this.widgetSaved.emit(true)
           this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("tender");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving tender settings!`, 'error')
      this._abd.hideSpinner()
    }
  }
  saveOnAllLocations(){
  //   console.log(this.tenderForm.value) 
  //    if(this._abd.locations.length < 1){
  //     return  this._messageService.add({ severity: 'error', summary: 'Location required. Please add a location to proceed.' })
  // } 

  //  this._confirmationService.confirm({
  //     message: 'Are you sure you want to apply these configurations to all locations?',
  //     header: 'Tender Configuration',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'Yes',
  //     rejectLabel: 'No',
  //     accept: () => {
        
        let locations = this._abd.locations.map((x)=>x.locationId).join(',') 
        let queryString = `AllLocation=true&BackOffice=true&LocationIds=${locations}`
          try{
            this._abd.showSpinner()
            this._businessSetupService.saveTenderConfiguration(queryString,this.tenderForm.value).subscribe(res=>{
              if(res.success){
                 this._connMessageService.showToastMessage(`Tender settings saved successfully!`, 'success')
                 this._abd.systemConfigs.tenderScreenConfig.isSaved = true
                 this._businessSetupService.showTenderConfiguration = false
                 this.widgetSaved.emit(true)
                 this._abd.getBusinessConfig().subscribe(res=>{
                              if(res.success){
                                this._businessSetupService.initWidget("tender");
                                this._abd.hideSpinner()
                              }
                            })
              }
            })
          }
          catch{
            this._connMessageService.showToastMessage(`Error saving tender settings!`, 'error')
            this._abd.hideSpinner()
          }
      // },
      // reject: () => {
      // }
    // });
 
 
  }

  cancelButtonClick(){
    this._businessSetupService.showTenderConfiguration = false
  }

}
