import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { WorldTimeZone } from 'src/app/_data/time-zones';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  _globals = GlobalService
  editform: FormGroup
  usaStates = UsStates
  showCityWait = false
  @Input() isViewOnly: boolean = false
  selectedState: any;
  emailValidatorString = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  @Input() singupBusinessConfig: boolean = true;
  selectedTimeZone:any;
  timeZoneList = WorldTimeZone
  
  constructor(
    private fb: FormBuilder,
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _locationService: LocationsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _userService: UserService,
    ) { }

  ngOnInit(): void {

    this.initForm()
    this.initFormEdit()
    let obj ={
      id:this._globals.userInfo.businessSetup[0]?.industry
    }
    if(this._globals.userInfo.businessSetup[0]?.standardsForms !== undefined && this._globals.userInfo.businessSetup[0]?.standardsForms?.timeZone !== "" ){
      this.selectedTimeZone = this.timeZoneList.filter((v)=>v.value === this._globals.userInfo.businessSetup[0]?.standardsForms.timeZone)[0]
    }else{
      this.selectedTimeZone = this.timeZoneList[0]
    }
    this.selectIndustryType(obj)
  }

  ngOnDestroy() {
    this._locationService.blankActiveItem()
  }

  initFormEdit() {
    const st = this.usaStates.filter(s => s.abbreviation == this._globals.userInfo.businessSetup[0].state)
    this.selectedState = st[0];
  }

  emailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this.emailValidatorString)
      return pvalid ? null : { emailStrength: true };
    }
  }

  initForm() {
  
   if(this._globals.userInfo.businessSetup[0].address === "" && this._globals.userInfo.businessSetup[0].isFreeTrial === false ){
    this._globals.userInfo.businessSetup[0].address =  this._globals.userInfo.businessSetup[0]?.subscription?.transactionDetails?.billTo.address
    this._globals.userInfo.businessSetup[0].city =  this._globals.userInfo.businessSetup[0]?.subscription?.transactionDetails?.billTo.city
    this._globals.userInfo.businessSetup[0].zip =  this._globals.userInfo.businessSetup[0]?.subscription?.transactionDetails?.billTo.zip

   }

    this.editform = this.fb.group({
      companyName: [{ value: this._globals.userInfo.businessSetup[0].businessDisplayName, disabled: this.isViewOnly }, Validators.required],
      companyAddress: [{value: this._globals.userInfo.businessSetup[0].address, disabled: this.isViewOnly }, Validators.required],
      companyCity: [{value: this._globals.userInfo.businessSetup[0].city, disabled: this.isViewOnly }, Validators.required],
      companyZip: [{value: this._globals.userInfo.businessSetup[0].zip, disabled: this.isViewOnly },[Validators.required]],
      companyPhone: [{value: this._globals.userInfo.businessSetup[0].phone, disabled: this.isViewOnly }, this._abd.phoneValidator()],
      companyEmail: [{value: this._globals.userInfo.username, disabled: true },[Validators.required, this.emailFormatValidator()]],
    })
  }

  get f(){
    return this.editform.controls
  }

  selectIndustryType(obj: any) {
    this._businessSetup.activeItem.industryType = obj.id
  }

  zipInputEvent(value: string) {
    if (!value || value.replace(/\D/g, '').length !== 5) return
    this.showCityWait = true
    this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
      this.showCityWait = false
      if (res.success && res.data.city != undefined) {
        const st = this.usaStates.filter(s => s.abbreviation == res.data.state)
        this.selectedState = st[0];
        this.editform.patchValue({ companyCity: res.data.city })
      } else {
        this.editform.patchValue({ companyCity: "Invalid zip code" })
        this.selectedState = this.usaStates[0]
      }
    })
  }

  saveFn(){
    // //console.log(this.f.companyName.value)
    // //console.log(this.f.companyAddress.value)
    // //console.log(this.f.companyCity.value)
    // //console.log(this.f.companyZip.value)
    // //console.log(this.f.companyPhone.value)
    // //console.log(this.f.companyEmail.value)
    // //console.log(this._businessSetup.activeItem.industryType)

    this._businessSetup.activeItem.StandardsForms.TimeZone = this.selectedTimeZone.value
    this._businessSetup.activeItem.UserID =       this._globals.userInfo.userID
    this._businessSetup.activeItem.businessType = ""
    this._businessSetup.activeItem.industryType = this._businessSetup.activeItem.industryType
    this._businessSetup.activeItem.ContactEmail = this._globals.userInfo.username
    
    console.log(this._businessSetup.activeItem)
    let bizInfoVariable = {
      contactEmail: this.f.companyEmail.value,
      phone: this.f.companyPhone.value,
      address: this.f.companyAddress.value,
      city: this.f.companyCity.value,
      zip: this.f.companyZip.value,
      state: this.selectedState.abbreviation,
      businessDisplayName: this.f.companyName.value,
      industry: this._businessSetup.activeItem.industryType.toString()
    }
     
    //console.log("GENERAL BIZ INFO",bizInfoVariable);

    try{
      this._abd.showSpinner()
      forkJoin([this._businessSetup.saveBusinessInformation(bizInfoVariable),this._userService.createStaffUser_ForSetupOnly(JSON.stringify(this._businessSetup.activeItem))]).subscribe(([res1,res2])=>{
        if(res1.success){
          this._connMessageService.showToastMessage(`Business Information saved successfully!`, 'success')
          
          this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res1=>{
            if(res1.success){
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving business information!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){

  }

}
